<template>
  <MobileDefault v-if="isMobile">
    <div class="container-bg-white container-space-mobile-default mobile-view">
      <h1 class="title"><span class="border-left-line"></span>Giới thiệu bạn bè - Nhận FREE gói Vip</h1>
      <div class="page-header">
        <div class="d-flex"><div class="line-name">Nhiệm vụ: </div><strong class="text-highlight">Giới thiệu một người mới.</strong></div>
        <div class="d-flex"><div class="line-name">Kết quả: </div><span>Bạn sẽ nhận Free Gói Vip mà người đó sử dụng.</span></div>
      </div>
      <div class="page-content">
        <div class="page-section-image">
          <img :src="urlSticker" alt="beecost-login" class="img-sticker">
          <h2 class="section-title">1. Đối tượng áp dụng</h2>
          <p>Chương trình dành cho tất cả người dùng của {{BRAND_NAME}}</p>
        </div>
        <template>
          <h2 class="section-title">2. Việc bạn cần làm</h2>
          <p v-if="marketReferralLink" class="step-title">Gửi link giới thiệu cho Bạn bè</p>
          <div v-else>
            <span class="text-highlight text-action" @click="onClickPricing">Nâng cấp Tài khoản</span> để đạt điều kiện tham gia. Hoặc <span class="text-highlight text-action" @click="onClickChatFacebook">liên hệ</span> chúng tôi
          </div>
          <div class="box-link-ref-container" v-if="marketReferralLink">
            <span class="text-highlight flex-shrink-0 my-2">Link mời bạn:</span>
            <div class="box-link-ref flex-shrink-0 border border-gray-200">
              <span class="text-link">{{marketReferralLink}}</span>
              <div class="tooltip btn-action-wrap" @click="onClickCopy(marketReferralLink, 'referral_link_tooltip_mobile')">
                <div class="btn-action" type="default" size="small">
                  <span class="tooltiptext" id="referral_link_tooltip_mobile">Đã copy</span>
                  <a-icon type="copy" />
                </div>
              </div>
            </div>
          </div>
          <h2 class="section-title">3. Khi nào bạn nhận được Khuyến mại?</h2>
          <p><span>Ngay khi người bạn mời nâng cấp một Gói Vip bất kì.</span></p>
          <p class=""><strong>Đặc biệt, Bạn cũng sẽ nhận ngay 01 Gói Vip Free đó.</strong></p>
        </template>
      </div>
      <div class="page-footer">
        <p class="footer-title text-highlight">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: translateY(2px)">
            <mask id="path-1-inside-1_139:1823" fill="white">
              <path d="M7.00039 2.64893C4.80972 2.64893 3.02734 4.48199 3.02734 6.73531C3.02734 8.24658 3.83913 9.62576 5.11698 10.3231V11.6842C5.11698 11.9185 5.30161 12.1084 5.5294 12.1084H8.47138C8.69917 12.1084 8.8838 11.9186 8.8838 11.6842V10.3336C10.1615 9.6181 10.9733 8.23425 10.9733 6.73531C10.9733 4.48214 9.19107 2.64893 7.00039 2.64893ZM8.29885 9.68697C8.15271 9.75626 8.05894 9.9067 8.05894 10.0723V11.26H5.94184V10.0581C5.94184 9.8906 5.84575 9.73858 5.69687 9.67057C4.5763 9.15828 3.85223 8.00603 3.85223 6.73531C3.85223 4.94988 5.26451 3.49731 7.00042 3.49731C8.73632 3.49731 10.1485 4.94988 10.1485 6.73531C10.1485 7.99599 9.42244 9.15462 8.29885 9.68697Z"/>
            </mask>
            <path d="M7.00039 2.64893C4.80972 2.64893 3.02734 4.48199 3.02734 6.73531C3.02734 8.24658 3.83913 9.62576 5.11698 10.3231V11.6842C5.11698 11.9185 5.30161 12.1084 5.5294 12.1084H8.47138C8.69917 12.1084 8.8838 11.9186 8.8838 11.6842V10.3336C10.1615 9.6181 10.9733 8.23425 10.9733 6.73531C10.9733 4.48214 9.19107 2.64893 7.00039 2.64893ZM8.29885 9.68697C8.15271 9.75626 8.05894 9.9067 8.05894 10.0723V11.26H5.94184V10.0581C5.94184 9.8906 5.84575 9.73858 5.69687 9.67057C4.5763 9.15828 3.85223 8.00603 3.85223 6.73531C3.85223 4.94988 5.26451 3.49731 7.00042 3.49731C8.73632 3.49731 10.1485 4.94988 10.1485 6.73531C10.1485 7.99599 9.42244 9.15462 8.29885 9.68697Z" fill="#FF8844" stroke="#FF8844" stroke-width="20" mask="url(#path-1-inside-1_139:1823)"/>
            <mask id="path-2-inside-2_139:1823" fill="white">
              <path d="M8.29512 12.4863H5.70363C5.45686 12.4863 5.25684 12.6557 5.25684 12.8648C5.25684 13.0738 5.45686 13.2432 5.70363 13.2432H8.29512C8.54189 13.2432 8.74191 13.0738 8.74191 12.8648C8.74194 12.6557 8.54192 12.4863 8.29512 12.4863Z"/>
            </mask>
            <path d="M8.29512 12.4863H5.70363C5.45686 12.4863 5.25684 12.6557 5.25684 12.8648C5.25684 13.0738 5.45686 13.2432 5.70363 13.2432H8.29512C8.54189 13.2432 8.74191 13.0738 8.74191 12.8648C8.74194 12.6557 8.54192 12.4863 8.29512 12.4863Z" fill="#FF8844" stroke="#FF8844" stroke-width="20" mask="url(#path-2-inside-2_139:1823)"/>
            <path d="M7.44635 1.79282V0.82474C7.44635 0.577973 7.24631 0.37793 6.99954 0.37793C6.75278 0.37793 6.55273 0.577974 6.55273 0.82474V1.79282C6.55273 2.03959 6.75278 2.23963 6.99954 2.23963C7.24631 2.23963 7.44635 2.03958 7.44635 1.79282Z" fill="#FF8844"/>
            <path d="M1.83774 6.50879H0.869663C0.622896 6.50879 0.422852 6.70883 0.422852 6.9556C0.422852 7.20237 0.622896 7.40241 0.869663 7.40241H1.83774C2.08451 7.40241 2.28455 7.20237 2.28455 6.9556C2.28455 6.70883 2.08451 6.50879 1.83774 6.50879Z" fill="#FF8844"/>
            <path d="M13.1297 6.50879H12.1617C11.9149 6.50879 11.7148 6.70883 11.7148 6.9556C11.7148 7.20237 11.9149 7.40241 12.1617 7.40241H13.1297C13.3765 7.40241 13.5765 7.20237 13.5765 6.9556C13.5765 6.70883 13.3765 6.50879 13.1297 6.50879Z" fill="#FF8844"/>
            <path d="M3.02872 3.09689L2.33861 2.41454C2.16314 2.24104 1.88023 2.24264 1.70673 2.41811C1.53323 2.59359 1.53483 2.87649 1.71031 3.04999L2.40042 3.73234C2.57589 3.90584 2.8588 3.90424 3.0323 3.72877C3.2058 3.55329 3.2042 3.27039 3.02872 3.09689Z" fill="#FF8844"/>
            <path d="M11.6612 2.41561L10.971 3.09796C10.7956 3.27146 10.794 3.55436 10.9675 3.72984C11.141 3.90531 11.4239 3.90691 11.5994 3.73341L12.2895 3.05106C12.4649 2.87756 12.4665 2.59466 12.293 2.41918C12.1195 2.24371 11.8366 2.24211 11.6612 2.41561Z" fill="#FF8844"/>
          </svg>
          <span class="margin-left-5">Góp ý hữu ích:</span>
        </p>
        <div class="footer-content">
          <p><span>Hãy giới thiệu tới những người mà bạn cho rằng {{BRAND_NAME}} hữu ích với họ. Khả năng họ nâng cấp gói Vip sẽ cao hơn.</span></p>
        </div>
      </div>
    </div>
  </MobileDefault>
  <Default v-else>
    <div class="container-bg-white container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Giới thiệu bạn bè - Nhận FREE gói Vip</h1>
      <div class="page-header">
        <div class="d-flex"><div class="line-name">Nhiệm vụ: </div><strong class="text-highlight">Giới thiệu một người mới.</strong></div>
        <div class="d-flex"><div class="line-name">Kết quả: </div>Bạn sẽ nhận Free Gói Vip mà người đó sử dụng.</div>
      </div>
      <div class="page-content">
        <img :src="urlSticker" alt="beecost-login" class="img-sticker">
        <h2 class="section-title">1. Đối tượng áp dụng</h2>
        <p>Chương trình dành cho tất cả người dùng của {{BRAND_NAME}}</p>
<!--        <p v-if="marketReferralLink" class="text-success">-->
<!--          <strong>Tài khoản của bạn đạt yêu cầu</strong>-->
<!--        </p>-->
<!--        <p v-else class="text-required font-23">-->
<!--          <strong>Tài khoản của bạn chưa đạt điều kiện tham gia</strong>-->
<!--        </p>-->
        <template>
          <h2 class="section-title">2. Việc bạn cần làm</h2>
          <h3 v-if="marketReferralLink" class="step-title">Gửi link giới thiệu cho Bạn bè</h3>
          <div v-else>
            <span class="text-highlight text-action" @click="onClickPricing">Nâng cấp Tài khoản</span> để đạt điều kiện tham gia. Hoặc <span class="text-highlight text-action" @click="onClickChatFacebook">liên hệ</span> chúng tôi
          </div>
          <ul>
            <div class="box-link-ref-container" v-if="marketReferralLink">
              <span class="text-highlight">Link giới thiệu của bạn:</span>
              <div class="box-link-ref">
                <span class="text-link">{{marketReferralLink}}</span>
                <div class="tooltip btn-action-wrap" @click="onClickCopy(marketReferralLink, 'referral_link_tooltip')">
                  <div class="btn-action" type="default" size="small">
                    <span class="tooltiptext" id="referral_link_tooltip">Đã copy</span>
                    <span>Copy</span>
                  </div>
                </div>
              </div>
            </div>
          </ul>
          <h2 class="section-title">3. Khi nào bạn nhận được Khuyến mại?</h2>
          <p><span>Khi người mà bạn giới thiệu nâng cấp một Gói Vip bất kì</span></p>
          <p class=""><strong>=> Bạn cũng nhận được Free Gói Vip ấy, ngay lập tức</strong></p>
        </template>
      </div>
      <div class="page-footer">
        <p class="footer-title text-highlight">Góp ý hữu ích:</p>
        <div class="footer-content">
          <p><span>Hãy giới thiệu tới những người mà bạn cho rằng {{BRAND_NAME}} hữu ích với họ.</span></p>
          <p><span>=> Khả năng họ nâng cấp gói Vip sẽ cao hơn</span></p>
        </div>
<!--        <div class="footer-content" v-if="promoteVoucher">-->
<!--          <p>Dùng thử {{BRAND_NAME}} với <a target="_blank" class="text-action" @click="onClickBtnPromote">Gói Khuyến Mại</a> dành cho riêng bạn.</p>-->
<!--        </div>-->
      </div>
    </div>
  </Default>
</template>

<script>


import Default from "@/layout/Default";
import {copyToClipboard} from "@/helper/StringHelper";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {BRAND_NAME, MESSENGER_ID} from "@/constant/general/GeneralConstant";
import {StickerConstants} from "@/constant/beecost/BeeCostResourceConstant";
import MobileDefault from "@/layout/MobileDefault";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "ReferralProgram",
  components: {
    Default,
    MobileDefault
  },
  data() {
    let urlSticker = getUrlGPhotoSize(StickerConstants.introduction, '256')
    return {
      urlSticker: urlSticker,
      BRAND_NAME: BRAND_NAME
    }
  },
  methods: {
    async onClickPricing() {
      await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPricing}`, true)
    },
    async onClickChatFacebook() {
      window.open(`https://messenger.com/t/${MESSENGER_ID}`, '_blank')
    },
    onClickCopy(text, tooltipId) {
      let result = copyToClipboard(text)
      if (result) {
        document.getElementById(tooltipId).classList.add('show')
        setTimeout(() => {
          document.getElementById(tooltipId).classList.remove('show')
        }, 700)
      }
    },
    onClickBtnPromote() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`, true)
    }
  },
  computed: {
    promoteVoucher: {
      get() {
        return this.$store.state.UserModule.promoteVoucher
      }
    },
    marketReferralLink() {
      return this.$store.state.UserModule.marketReferralLink
    }
  },
  async mounted() {

  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style lang="scss" scoped>

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 8px;

  button {
    border: none;
    background: rgba(180, 185, 230, 0.27);
    color: $--color-primary;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: $--color-primary;
  position: absolute;
  z-index: 1;
  text-align: left;
  opacity: 0;
  transition: opacity 0.5s;
  left: 110%;
}

.tooltip .tooltiptext#popup_upgrade_tooltip {
  left: 100%;
  bottom: 0;
  padding-left: 10px;
}

.tooltip .tooltiptext.show {
  visibility: visible;
  opacity: 1;
}

.page-header {
  margin-left: 14px;
  font-size: 20px;
  line-height: 24px;

  .line-name {
    font-weight: 600;
    width: 100px;
  }
}

.page-content {
  position: relative;
  margin-top: 12px;
  padding: 0 0 8px 138px;
  font-size: 16px;
  line-height: 24px;

  .img-sticker {
    position: absolute;
    top: 0;
    left: 16px;
    height: auto;
    width: 70px;
    transform: scale(1.5);
  }

  .section-title {
    font-size: 24px;
    margin-top: 24px;
    margin-left: -24px;
  }

  .step-title {
    font-size: 18px;
    margin-top: 10px;
  }

  .box-link-ref-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.disabled {
      .box-link-ref {
        background-color: rgba(gray, 0.1);

        .btn-action-wrap {
          background-color: rgba(gray, 0.9);
        }
      }
    }

    .box-link-ref {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: rgba($--color-primary, 0.1);
      border-radius: 4px;
      margin-left: 8px;

      .text-link {
        margin: 10px;
        user-select: none;
      }

      .btn-action-wrap {
        height: 100%;
        background-color: rgba($--color-primary, 0.9);
        color: white;
        padding: 10px 12px;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
      }
    }
  }
}

.page-footer {
  margin-left: 14px;
  font-size: 16px;
  line-height: 24px;

  .footer-title {
    font-size: 24px;
    margin-top: 24px;
  }

  .footer-content {
    margin-left: 24px;
    margin-top: 12px;
  }
}

.title {
  font-size: 28px;
}

.text-note {
  font-size: 20px;
  line-height: 24px;
}

.text-success {
  color: #096dd9;
}

.text-highlight {
  color: $--color-primary;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.text-required {
  color: #ee0033;
}

.text-default {
  color: rgba(0, 0, 0, 0.85);
}

.text-action {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
  }
}

.mobile-view {

  h1.title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .border-left-line {
    border-left-width: 1px;
    padding-right: 6px;
    font-size: 19px;
  }

  .page-header {
    margin-left: 0;
    font-size: 14px;
    line-height: 18px;

    .line-name {
      font-weight: 500;
      width: 100px;
      flex-shrink: 0;
      margin-bottom: 6px;
    }
  }

  .page-content {
    font-size: 12px;
    line-height: 16px;
    padding: 0;

    .page-section-image {
      position: relative;
      padding: 0 0 8px 100px;
      font-size: 12px;
      line-height: 16px;
    }

    .img-sticker {
      position: absolute;
      top: -28px;
      left: 0;
      height: auto;
      width: 80px;
      transform: scale(1);
    }

    .section-title {
      font-size: 14px;
      margin-top: 12px;
      margin-left: 0px;
    }

    .step-title {
      font-size: 12px;
      margin-top: 10px;
    }

    .box-link-ref-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &.disabled {
        .box-link-ref {
          background-color: unset;

          .btn-action-wrap {
            background-color: unset;
          }
        }
      }

      .box-link-ref {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: unset;
        border-radius: 4px;
        margin-left: 0;

        .text-link {
          margin: 8px;
          user-select: none;
        }

        .btn-action-wrap {
          height: 100%;
          background-color: unset;
          color: $--color-primary;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .page-footer {
    font-size: 12px;
    line-height: 16px;
    margin-left: 0;

    .footer-title {
      font-size: 12px;
      line-height: 16px;
    }

    .footer-content {
      margin-left: 0px;
      margin-top: 4px;
    }
  }

  .tooltip .tooltiptext {
    width: 100%;
    left: 0%;
    top: -100%;
  }
}
</style>

